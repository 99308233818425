var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ApprovalcenterHead" }, [
    _c("div", { staticClass: "head-left" }, [
      _c("div", { staticClass: "head-all" }, [
        _c("ul", [
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.highlight("all", "", "")
                },
              },
            },
            [
              _c("div", { staticClass: "head-container" }, [
                _c(
                  "span",
                  { class: _vm.active == "all" ? "activeNum" : "approvalNum" },
                  [_vm._v(_vm._s(_vm.allNum))]
                ),
                _c("br"),
                _c(
                  "span",
                  {
                    class: _vm.active == "all" ? "activeText" : "approvelText",
                  },
                  [_vm._v("全部")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "head-status" }, [
        _c(
          "ul",
          _vm._l(_vm.statusList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function ($event) {
                    return _vm.highlight(item.name, _vm.typeList[item.type], "")
                  },
                },
              },
              [
                _c("div", { staticClass: "head-container" }, [
                  _c(
                    "span",
                    {
                      class:
                        _vm.active == item.name ? "activeNum" : "approvalNum",
                    },
                    [_vm._v(_vm._s(item.num))]
                  ),
                  _c("br"),
                  _c(
                    "span",
                    {
                      class:
                        _vm.active == item.name ? "activeText" : "approvelText",
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm.childList.length > 0
      ? _c(
          "div",
          { staticClass: "head-function" },
          [
            _c(
              "a-carousel",
              _vm._l(_vm.childList, function (it, index1) {
                return _c(
                  "ul",
                  { key: "carousel-ul" + index1, staticClass: "carouser-ul" },
                  _vm._l(it, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.highlight(
                              item.groupCode,
                              "",
                              item.flowGroupId
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "head-container" }, [
                          _c(
                            "span",
                            {
                              class:
                                _vm.active == item.groupCode
                                  ? "activeNum"
                                  : "approvalNum",
                            },
                            [_vm._v(_vm._s(item.num))]
                          ),
                          _c("br"),
                          _c(
                            "span",
                            {
                              class:
                                _vm.active == item.groupCode
                                  ? "activeText"
                                  : "approvelText",
                            },
                            [_vm._v(_vm._s(item.groupName))]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }